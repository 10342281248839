import { PayItem } from "@/views/home/type"

export const infoList = [
    {
        title: '多种监管并行',
        des: '店主自主巡查、电商平台自带风控、代理商风控审查、天平大数据算法风控。',
        mainBg: require("@/assets/banner/third.png"),
        imgList: [
            {
                src: require('@/assets/banner/third_1.png'),
                alt: '图1',
                class: 'left-top',
            },
            {
                src: require('@/assets/banner/third_2.png'),
                alt: '图2',
                class: 'right-top',
            },
            {
                src: require('@/assets/banner/third_3.png'),
                alt: '图3',
                class: 'right-bottom',
            },
        ]
    },
    {
        title: '违规处罚机制',
        des: '店主自主巡查、电商平台自带风控、代理商风控审查、天平大数据算法风控。',
        mainBg: require("@/assets/banner/info-2-1.png"),
        imgList: [
            {
                src: require('@/assets/banner/info-2-2.png'),
                alt: '图2',
                class: 'right-top_1',
            },
            {
                src: require('@/assets/banner/info-2-3.png'),
                alt: '图3',
                class: 'right-bottom_1',
            },
        ]
    },
    {
        title: '平台代扣税金',
        des: '店主自主巡查、电商平台自带风控、代理商风控审查、天平大数据算法风控。',
        mainBg: require("@/assets/banner/info-3-1.png"),
        imgList: [
            {
                src: require('@/assets/banner/info-3-2.png'),
                alt: '图1',
                class: 'left-top_2',
            },
            {
                src: require('@/assets/banner/info-3-3.png'),
                alt: '图2',
                class: 'right-top_2',
            },
            {
                src: require('@/assets/banner/info-3-4.png'),
                alt: '图3',
                class: 'right-bottom_2',
            },
        ]
    },
    {
        title: '法务税务支持',
        des: '店主自主巡查、电商平台自带风控、代理商风控审查、天平大数据算法风控。',
        mainBg: require("@/assets/banner/info-4-1.png"),
        imgList: [
            {
                src: require('@/assets/banner/info-4-2.png'),
                alt: '图1',
                class: 'left-top_3',
            },
            {
                src: require('@/assets/banner/info-4-4.png'),
                alt: '图2',
                class: 'right-top_3',
            },
            {
                src: require('@/assets/banner/info-4-3.png'),
                alt: '图3',
                class: 'right-bottom_3',
            },
        ]
    },
    {
        title: '三方履约担保',
        des: '店主自主巡查、电商平台自带风控、代理商风控审查、天平大数据算法风控。',
        mainBg: require("@/assets/banner/info-5-1.png"),
        imgList: [
            {
                src: require('@/assets/banner/info-5-2.png'),
                alt: '图1',
                class: 'left-top_4',
            },
            {
                src: require('@/assets/banner/info-5-3.png'),
                alt: '图2',
                class: 'right-top_4',
            },
            {
                src: require('@/assets/banner/info-5-4.png'),
                alt: '图3',
                class: 'right-bottom_4',
            },
        ]
    }
]
export const payList: Array<PayItem> = [
    {
        id: 'pay1',
        img: require("@/assets/payIcon/1.png")
    },
    {
        id: 'pay2',
        img: require("@/assets/payIcon/2.png")
    },
    {
        id: 'pay3',
        img: require("@/assets/payIcon/3.png")
    },
    {
        id: 'pay4',
        img: require("@/assets/payIcon/4.png")
    },
    {
        id: 'pay5',
        img: require("@/assets/payIcon/5.png")
    },
    {
        id: 'pay6',
        img: require("@/assets/payIcon/6.png")
    }
]

export const avatarList = [
    {
        name: '刘*枫',
        address: '彰化',
        img: require("@/assets/banner/avatar-1.png"),
        desc: '平台使用方便，安全有保障，用的放心！',
        star: require("@/assets/banner/start-1.png")
    },
    {
        name: '王*伟',
        address: '新竹',
        img: require("@/assets/banner/avatar-2.png"),
        desc: '运营很重要，我现在合作的运营不错。',
        star: require("@/assets/banner/start-2.png")
    },
    {
        name: '张*华',
        address: '台北',
        img: require("@/assets/banner/avatar-3.png"),
        desc: '合作一年了，每个月都可以赚些零花钱。',
        star: require("@/assets/banner/start-1.png")
    },
    {
        name: '林*佳',
        address: '彰化',
        img: require("@/assets/banner/avatar-4.png"),
        desc: '刚开始我还以为是诈骗，后来发现是真的。',
        star: require("@/assets/banner/start-2.png")
    },
    {
        name: '刘*毅',
        address: '高雄',
        img: require("@/assets/banner/avatar-5.png"),
        desc: '合作已经几年了，很好的平台。',
        star: require("@/assets/banner/start-2.png")
    },
    {
        name: '张*艾',
        address: '高雄',
        img: require("@/assets/banner/avatar-6.png"),
        desc: '有保证金在，不怕“跑路”放心用！',
        star: require("@/assets/banner/start-2.png")
    },
]
export const avatarList2 = [
    {
        name: '李*飞',
        address: '台北',
        img: require("@/assets/banner/avatar-7.png"),
        desc: '作为一名店家，能找到此平台合作真是万幸！',
        star: require("@/assets/banner/start-1.png")
    },
    {
        name: '李*鹏',
        address: '桃园',
        img: require("@/assets/banner/avatar-8.png"),
        desc: '平台很省心，每天都有进账。',
        star: require("@/assets/banner/start-2.png")
    },
    {
        name: '刘*楠',
        address: '台南',
        img: require("@/assets/banner/avatar-9.png"),
        desc: '很好的平台，希望越来越好！',
        star: require("@/assets/banner/start-1.png")
    },
    {
        name: '林*佳',
        address: '彰化',
        img: require("@/assets/banner/avatar-10.png"),
        desc: '刚开始我还以为是诈骗，后来发现是真的。',
        star: require("@/assets/banner/start-2.png")
    },
    {
        name: '马*',
        address: '基隆',
        img: require("@/assets/banner/avatar-11.png"),
        desc: '合作的还可以，而且有帮我课税。',
        star: require("@/assets/banner/start-2.png")
    },
    {
        name: '王*慧',
        address: '基隆',
        img: require("@/assets/banner/avatar-12.png"),
        desc: '好几个朋友有在用，都挺不错的！',
        star: require("@/assets/banner/start-2.png")
    },
]
