import {
    ArrowDown,
    ArrowUp,
    ArrowRight,
    ArrowLeft,
    ArrowDownBold,
    ArrowUpBold,
    ArrowRightBold,
    ArrowLeftBold,
} from "@element-plus/icons-vue";
import { App } from "vue";

const icons = [
    ArrowDown,
    ArrowUp,
    ArrowRight,
    ArrowLeft,
    ArrowDownBold,
    ArrowUpBold,
    ArrowRightBold,
    ArrowLeftBold,
]
export default function InitElementIcons(app: App) {
    icons.forEach(icon => {
        app.component(icon.name as string, icon)
    })
}
