export const baseRoute: Array<Router.RouteItem> = [
    {
        path: '/home',
        name: 'HomeIndex',
    },
    {
        path: '/service/shop',
        name: 'ServiceShop',
    },
    {
        path: '/service/operation',
        name: 'ServiceOperation',
    },
    {
        path: '/partner/business',
        name: 'PartnerBusiness',
    },
    {
        path: '/partner/ecology',
        name: 'PartnerEcology',
    },
    {
        path: '/about',
        name: 'AboutIndex',
    },
    {
        path: '/about1',
        name: 'AboutXshopeeIndex',
    }
]