import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { baseRoute } from './baseRoute'
import { routeEngineering } from './initRoute'

const routes: Array<RouteRecordRaw> = routeEngineering(baseRoute as RouteRecordRaw[]);
console.log('routes',routes)
const router = createRouter({
  history: createWebHistory('/'),
  routes: [
    {
      path: '/',
      name: "Layout",
      component: () => import('@/layout/layoutMain.vue'),
      redirect: '/home',
      children: [...routes]
    },
  ],
})
export default router