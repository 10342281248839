import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue";
import _imports_0 from '@/assets/banner/first.png';
import _imports_1 from '@/assets/banner/secend.png';
import _imports_2 from '@/assets/logo/logo.png';
const _hoisted_1 = {
  class: "home-index--container"
};
const _hoisted_2 = {
  class: "box"
};
const _hoisted_3 = {
  class: "primary-title"
};
const _hoisted_4 = {
  class: "title"
};
const _hoisted_5 = {
  class: "des"
};
const _hoisted_6 = {
  class: "btn"
};
const _hoisted_7 = {
  class: "page_item_2"
};
const _hoisted_8 = {
  class: "item_top"
};
const _hoisted_9 = {
  class: "text-box"
};
const _hoisted_10 = {
  class: "title"
};
const _hoisted_11 = {
  class: "des"
};
const _hoisted_12 = {
  class: "item_bottom"
};
const _hoisted_13 = ["src", "alt"];
const _hoisted_14 = {
  class: "title"
};
const _hoisted_15 = {
  class: "des"
};
const _hoisted_16 = {
  class: "page_item_3"
};
const _hoisted_17 = {
  class: "left"
};
const _hoisted_18 = ["onClick"];
const _hoisted_19 = {
  class: "title"
};
const _hoisted_20 = {
  key: 0,
  class: "des"
};
const _hoisted_21 = ["src", "alt"];
const _hoisted_22 = {
  class: "page_item_4"
};
const _hoisted_23 = {
  class: "item_right"
};
const _hoisted_24 = ["src", "alt"];
const _hoisted_25 = {
  class: "page_item_5"
};
const _hoisted_26 = {
  class: "inner_box"
};
const _hoisted_27 = {
  class: "sign_box"
};
const _hoisted_28 = {
  class: "btn"
};
const _hoisted_29 = {
  class: "sign_site_wrapper"
};
const _hoisted_30 = ["src"];
import { Swiper, SwiperSlide } from 'swiper/vue';
import { signList, payList } from './data';
import 'swiper/css';
import { infoList } from './data';
import initHook from './composables/initHook';
import OperationHook from './composables/operationHook';
const __default__ = {
  name: 'HomeIndex'
};
export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {
    const {
      activeIndex
    } = initHook();
    const {
      clickInfoHandle
    } = OperationHook({
      activeIndex
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_unref(Swiper), {
        ref: "swiper",
        class: "scroll_nav",
        "space-between": 50,
        "centered-slides": true,
        pagenation: true
      }, {
        default: _withCtx(() => [_createVNode(_unref(SwiperSlide), {
          class: "swiper_box"
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('homeText.first')), 1), _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('homeText.title')), 1), _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('homeText.des')), 1), _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('btn.contactUs')), 1), _cache[0] || (_cache[0] = _createElementVNode("div", {
            class: "img-box"
          }, [_createElementVNode("img", {
            class: "main-img",
            src: _imports_0,
            alt: ""
          })], -1))])]),
          _: 1
        })]),
        _: 1
      }, 512), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, [_cache[1] || (_cache[1] = _createElementVNode("div", {
        class: "img-box"
      }, [_createElementVNode("img", {
        class: "main-img",
        src: _imports_1,
        alt: ""
      })], -1)), _createElementVNode("div", _hoisted_9, [_createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.$t('homeText.safeTextTitle')), 1), _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.$t('homeText.safeTextDes')), 1)])]), _createElementVNode("div", _hoisted_12, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(signList), item => {
        return _openBlock(), _createElementBlock("li", {
          key: item.id
        }, [_createElementVNode("img", {
          src: item.img,
          alt: item.title
        }, null, 8, _hoisted_13), _createElementVNode("p", _hoisted_14, _toDisplayString(item.title), 1), _createElementVNode("p", _hoisted_15, _toDisplayString(item.des), 1)]);
      }), 128))])])]), _createElementVNode("div", _hoisted_16, [_cache[2] || (_cache[2] = _createElementVNode("header", null, " 让店主收益可持续 ", -1)), _createElementVNode("section", null, [_createElementVNode("ul", _hoisted_17, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(infoList), (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          key: index,
          class: _normalizeClass(_unref(activeIndex) === index ? 'active' : ''),
          onClick: $event => _unref(clickInfoHandle)(index)
        }, [_createElementVNode("span", _hoisted_19, _toDisplayString(item.title), 1), _unref(activeIndex) === index ? (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString(item.des), 1)) : _createCommentVNode("", true)], 10, _hoisted_18);
      }), 128))]), _createElementVNode("div", {
        class: "right",
        style: _normalizeStyle({
          backgroundImage: `url(${_unref(infoList)[_unref(activeIndex)].mainBg})`
        })
      }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(infoList)[_unref(activeIndex)].imgList, (item, index) => {
        return _openBlock(), _createElementBlock("img", {
          class: _normalizeClass(item.class),
          key: index,
          src: item.src,
          alt: item.alt
        }, null, 10, _hoisted_21);
      }), 128))], 4)])]), _createElementVNode("div", _hoisted_22, [_cache[3] || (_cache[3] = _createElementVNode("div", {
        class: "item_left"
      }, [_createElementVNode("p", {
        class: "title"
      }, "合作支付机构"), _createElementVNode("span", {
        class: "des"
      }, " XShopee与多家支付机构合作， 致力于为您打造更为便捷的支付体验。 ")], -1)), _createElementVNode("div", _hoisted_23, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(payList), item => {
        return _openBlock(), _createElementBlock("li", {
          key: item.id
        }, [_createElementVNode("img", {
          src: item.img,
          alt: item.id
        }, null, 8, _hoisted_24)]);
      }), 128))])])]), _createElementVNode("div", _hoisted_25, [_createElementVNode("div", _hoisted_26, [_createElementVNode("div", _hoisted_27, [_cache[4] || (_cache[4] = _createElementVNode("img", {
        src: _imports_2,
        alt: ""
      }, null, -1)), _cache[5] || (_cache[5] = _createElementVNode("p", {
        class: "title"
      }, "为全球本土店跨境联营保驾护航", -1)), _createElementVNode("div", _hoisted_28, _toDisplayString(_ctx.$t('btn.contactUs')), 1)]), _createElementVNode("div", _hoisted_29, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(7, item => {
        return _createElementVNode("div", {
          class: _normalizeClass(`site_item${item}`),
          key: item
        }, [_createElementVNode("img", {
          src: require(`@/assets/site/${item}.png`)
        }, null, 8, _hoisted_30), _cache[6] || (_cache[6] = _createElementVNode("div", {
          class: "site_item_glint"
        }, null, -1))], 2);
      }), 64))])])])]);
    };
  }
});