export default {
  name: 'PartnerIndex',
  data() {
    return {
      active: 0,
      list: [{
        name: '媒体网红',
        img: require('@/assets/banner/yw_img_mtwh02.png'),
        active: require('@/assets/banner/yw_img_mtwh01.png'),
        en: 'medium',
        en2: 'KOL master',
        desc: '您是媒体或者KOL达人，具有一定的知名度、公信度和权威性，欢迎成为我们的业务伙伴'
      }, {
        name: '行业协会',
        img: require('@/assets/banner/yw_img_hyxh02.png'),
        active: require('@/assets/banner/yw_img_hyxh01.png'),
        en: 'trade associations',
        en2: 'Principal',
        desc: '您是商协会的负责人，在当地有一定的影响力，欢迎成为我们的业务伙伴。'
      }, {
        name: '其他组织',
        img: require('@/assets/banner/yw_img_qtzz02.png'),
        active: require('@/assets/banner/yw_img_qtzz01.png'),
        en: 'institution',
        en2: 'tissue',
        desc: '您是其他中立机构或组织，通过平台审核之后，也可以成为我们的业务伙伴。'
      }],
      joinList: [{
        name: "提交申请",
        desc: "向天平平台提交您的推广申请",
        index: "01",
        img: require('@/assets/banner/yw_icon_tjsq.png')
      }, {
        name: "平台审核",
        desc: "天平审核后，获得专属邀请码和邀请链接",
        index: "02",
        img: require('@/assets/banner/yw_icon_ptsh.png')
      }, {
        name: "商务对接",
        desc: "商务经理对接，提供推广素材和推广链接",
        index: "03",
        img: require('@/assets/banner/yw_icon_tgzq.png')
      }, {
        name: "推广赚钱",
        desc: "通过邀请码或邀请链接推广，获取佣金",
        index: "04",
        img: require('@/assets/banner/yw_icon_tgzq.png')
      }]
    };
  },
  methods: {
    contactUs() {
      console.log('contactUs');
    }
  }
};