import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue";
import _imports_0 from '@/assets/banner/service-operation-bg-2.png';
import _imports_1 from '@/assets/banner/service-operation-bg-1.png';
import _imports_2 from '@/assets/banner/secend.png';
import _imports_3 from '@/assets/logo/logo.white.png';
const _hoisted_1 = {
  class: "service-operation"
};
const _hoisted_2 = {
  class: "page_item_2"
};
const _hoisted_3 = {
  class: "item_top"
};
const _hoisted_4 = {
  class: "text-box"
};
const _hoisted_5 = {
  class: "title"
};
const _hoisted_6 = {
  class: "des"
};
const _hoisted_7 = {
  class: "item_bottom"
};
const _hoisted_8 = ["src", "alt"];
const _hoisted_9 = {
  class: "title"
};
const _hoisted_10 = {
  class: "des"
};
const _hoisted_11 = {
  class: "page_item_4"
};
const _hoisted_12 = {
  class: "item_right"
};
const _hoisted_13 = ["src", "alt"];
const _hoisted_14 = {
  class: "page_item_5"
};
const _hoisted_15 = {
  class: "avatar-list"
};
const _hoisted_16 = {
  class: "avatar-item-top-left"
};
const _hoisted_17 = {
  class: "name-info"
};
const _hoisted_18 = {
  class: "name"
};
const _hoisted_19 = {
  class: "address"
};
const _hoisted_20 = ["src"];
const _hoisted_21 = {
  class: "desc"
};
const _hoisted_22 = {
  class: "title"
};
const _hoisted_23 = {
  class: "des"
};
import { signList, payList, avatarList } from './data';
const __default__ = {
  name: 'ServiceOperation'
};
export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_cache[3] || (_cache[3] = _createStaticVNode("<header data-v-a19d75c4><div class=\"left\" data-v-a19d75c4><div class=\"title\" data-v-a19d75c4><span style=\"color:#2961FE;\" data-v-a19d75c4>天天平台</span><span data-v-a19d75c4>保障运营回款安全</span></div><div class=\"des\" data-v-a19d75c4><div data-v-a19d75c4>XShopee(天平平台)通过担保交易模式，让运营安全合规回款，</div><div data-v-a19d75c4>不再担心“跑路”风险！</div></div><div class=\"concat-us\" data-v-a19d75c4> 联系我们 </div></div><div class=\"right\" data-v-a19d75c4><img src=\"" + _imports_0 + "\" alt=\"\" data-v-a19d75c4><img src=\"" + _imports_1 + "\" alt=\"\" data-v-a19d75c4></div></header>", 1)), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_cache[0] || (_cache[0] = _createElementVNode("div", {
        class: "img-box"
      }, [_createElementVNode("img", {
        class: "main-img",
        src: _imports_2,
        alt: ""
      })], -1)), _createElementVNode("div", _hoisted_4, [_createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('homeText.safeTextTitle')), 1), _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('homeText.safeTextDes')), 1)])]), _createElementVNode("div", _hoisted_7, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(signList), item => {
        return _openBlock(), _createElementBlock("li", {
          key: item.id
        }, [_createElementVNode("img", {
          src: item.img,
          alt: item.title
        }, null, 8, _hoisted_8), _createElementVNode("p", _hoisted_9, _toDisplayString(item.title), 1), _createElementVNode("p", _hoisted_10, _toDisplayString(item.des), 1)]);
      }), 128))])])]), _createElementVNode("div", _hoisted_11, [_cache[1] || (_cache[1] = _createElementVNode("div", {
        class: "item_left"
      }, [_createElementVNode("p", {
        class: "title"
      }, "合作支付机构"), _createElementVNode("span", {
        class: "des"
      }, " XShopee与多家支付机构合作， 致力于为您打造更为便捷的支付体验。 ")], -1)), _createElementVNode("div", _hoisted_12, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(payList), item => {
        return _openBlock(), _createElementBlock("li", {
          key: item.id
        }, [_createElementVNode("img", {
          src: item.img,
          alt: item.id
        }, null, 8, _hoisted_13)]);
      }), 128))])])]), _createElementVNode("div", _hoisted_14, [_cache[2] || (_cache[2] = _createElementVNode("div", {
        class: "header"
      }, "他/她们都在用XShopee", -1)), _createElementVNode("div", _hoisted_15, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(avatarList), (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          key: index,
          class: "avatar-item"
        }, [_createElementVNode("div", _hoisted_16, [_createElementVNode("div", _hoisted_17, [_createElementVNode("div", _hoisted_18, _toDisplayString(item.company), 1), _createElementVNode("div", _hoisted_19, _toDisplayString(item.name), 1)]), _createElementVNode("img", {
          src: item.img,
          alt: "#"
        }, null, 8, _hoisted_20)]), _createElementVNode("div", _hoisted_21, [_createElementVNode("div", _hoisted_22, _toDisplayString(item.title), 1), _createElementVNode("div", _hoisted_23, _toDisplayString(item.desc), 1)])]);
      }), 128))])]), _cache[4] || (_cache[4] = _createStaticVNode("<div class=\"footer\" data-v-a19d75c4><div class=\"main-content\" data-v-a19d75c4><div class=\"left\" data-v-a19d75c4><div data-v-a19d75c4><img src=\"" + _imports_3 + "\" alt=\"\" data-v-a19d75c4></div><div data-v-a19d75c4>保障运营回款安全！</div></div><div class=\"right\" data-v-a19d75c4><span data-v-a19d75c4>联系我们</span></div></div></div>", 1))]);
    };
  }
});