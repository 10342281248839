import IconSecure from '@/assets/icon/icon_secure.png'
import IconEfficient from "@/assets/icon/icon_efficient.png"
import IconTransparent from "@/assets/icon/icon_transparent.png"
import IconCompliance from "@/assets/icon/icon_compliance.png"
import { PayItem, SignItem } from './type'
export const signList: Array<SignItem> = [
  {
    title: "安全",
    des: "每笔订单店主必须预存保证金在平台",
    img: IconSecure,
    id: Symbol('安全')
  },
  {
    title: "高效",
    des: "订单完成拨款，系统T+0自动结算到平台钱包",
    img: IconEfficient,
    id: Symbol('高效')
  },
  {
    title: "透明",
    des: "没有中间商赚差价，直通店主透明合作",
    img: IconTransparent,
    id: Symbol('透明')
  },
  {
    title: "合规",
    des: "所有资金通过银行、三方支付机构监管",
    img: IconCompliance,
    id: Symbol('合规')
  }
]
export const payList: Array<PayItem> = [
  {
    id: 'pay1',
    img: require("@/assets/payIcon/1.png")
  },
  {
    id: 'pay2',
    img: require("@/assets/payIcon/2.png")
  },
  {
    id: 'pay3',
    img: require("@/assets/payIcon/3.png")
  },
  {
    id: 'pay4',
    img: require("@/assets/payIcon/4.png")
  },
  {
    id: 'pay5',
    img: require("@/assets/payIcon/5.png")
  },
  {
    id: 'pay6',
    img: require("@/assets/payIcon/6.png")
  }
]

export const infoList = [
  {
    title: '多种监管并行',
    des: '店主自主巡查、电商平台自带风控、代理商风控审查、天平大数据算法风控。',
    mainBg: require("@/assets/banner/third.png"),
    imgList: [
      {
        src: require('@/assets/banner/third_1.png'),
        alt: '图1',
        class: 'left-top',
      },
      {
        src: require('@/assets/banner/third_2.png'),
        alt: '图2',
        class: 'right-top',
      },
      {
        src: require('@/assets/banner/third_3.png'),
        alt: '图3',
        class: 'right-bottom',
      },
    ]
  },
  {
    title: '违规处罚机制',
    des: '店主自主巡查、电商平台自带风控、代理商风控审查、天平大数据算法风控。',
    mainBg: require("@/assets/banner/info-2-1.png"),
    imgList: [
      {
        src: require('@/assets/banner/info-2-2.png'),
        alt: '图2',
        class: 'right-top_1',
      },
      {
        src: require('@/assets/banner/info-2-3.png'),
        alt: '图3',
        class: 'right-bottom_1',
      },
    ]
  },
  {
    title: '平台代扣税金',
    des: '店主自主巡查、电商平台自带风控、代理商风控审查、天平大数据算法风控。',
    mainBg: require("@/assets/banner/info-3-1.png"),
    imgList: [
      {
        src: require('@/assets/banner/info-3-2.png'),
        alt: '图1',
        class: 'left-top_2',
      },
      {
        src: require('@/assets/banner/info-3-3.png'),
        alt: '图2',
        class: 'right-top_2',
      },
      {
        src: require('@/assets/banner/info-3-4.png'),
        alt: '图3',
        class: 'right-bottom_2',
      },
    ]
  },
  {
    title: '法务税务支持',
    des: '店主自主巡查、电商平台自带风控、代理商风控审查、天平大数据算法风控。',
    mainBg: require("@/assets/banner/info-4-1.png"),
    imgList: [
      {
        src: require('@/assets/banner/info-4-2.png'),
        alt: '图1',
        class: 'left-top_3',
      },
      {
        src: require('@/assets/banner/info-4-4.png'),
        alt: '图2',
        class: 'right-top_3',
      },
      {
        src: require('@/assets/banner/info-4-3.png'),
        alt: '图3',
        class: 'right-bottom_3',
      },
    ]
  },
  {
    title: '三方履约担保',
    des: '店主自主巡查、电商平台自带风控、代理商风控审查、天平大数据算法风控。',
    mainBg: require("@/assets/banner/info-5-1.png"),
    imgList: [
      {
        src: require('@/assets/banner/info-5-2.png'),
        alt: '图1',
        class: 'left-top_4',
      },
      {
        src: require('@/assets/banner/info-5-3.png'),
        alt: '图2',
        class: 'right-top_4',
      },
      {
        src: require('@/assets/banner/info-5-4.png'),
        alt: '图3',
        class: 'right-bottom_4',
      },
    ]
  }
]