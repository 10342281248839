import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue";
import _imports_0 from '@/assets/banner/生态banner1920.png';
import _imports_1 from '@/assets/banner/service-footer-bg.png';
import _imports_2 from '@/assets/logo/logo.white.png';
const _hoisted_1 = {
  class: "partner-wrapper",
  style: {
    "background-color": "#f6faff"
  }
};
const _hoisted_2 = {
  class: "py-5 partner"
};
const _hoisted_3 = {
  class: "scroll-container"
};
const _hoisted_4 = {
  class: "scroll-content py-3 d-flex",
  style: {
    "animation-delay": "0.5s"
  }
};
const _hoisted_5 = {
  class: "p-4 bg-white d-flex flex-column rounded rounded-4"
};
const _hoisted_6 = {
  class: "d-flex justify-content-between align-items-center"
};
const _hoisted_7 = {
  class: "d-flex align-items-center"
};
const _hoisted_8 = ["src"];
const _hoisted_9 = {
  class: "px-2"
};
const _hoisted_10 = {
  class: "fs-5 fw-bold"
};
const _hoisted_11 = {
  class: "text-secondary"
};
const _hoisted_12 = {
  class: "avatar-item-top-right"
};
const _hoisted_13 = ["src"];
const _hoisted_14 = {
  class: "py-4 fs-5 fw-bold"
};
const _hoisted_15 = {
  class: "scroll-content py-3 d-flex"
};
const _hoisted_16 = {
  class: "p-4 bg-white d-flex flex-column rounded rounded-4"
};
const _hoisted_17 = {
  class: "d-flex justify-content-between align-items-center"
};
const _hoisted_18 = {
  class: "d-flex align-items-center"
};
const _hoisted_19 = ["src"];
const _hoisted_20 = {
  class: "px-2"
};
const _hoisted_21 = {
  class: "fs-5 fw-bold"
};
const _hoisted_22 = {
  class: "text-secondary"
};
const _hoisted_23 = {
  class: "avatar-item-top-right"
};
const _hoisted_24 = ["src"];
const _hoisted_25 = {
  class: "py-4 fs-5 fw-bold"
};
const _hoisted_26 = {
  class: "container py-5 bg-white recruit"
};
const _hoisted_27 = {
  class: "my-5 d-flex"
};
const _hoisted_28 = ["src"];
const _hoisted_29 = {
  class: "position-absolute start-0 top-0 w-100 h-100"
};
const _hoisted_30 = {
  class: "ms-3 mt-3 ps-2 d-flex align-items-center"
};
const _hoisted_31 = {
  class: "ms-3 ps-2 d-flex align-items-center"
};
const _hoisted_32 = {
  class: "ms-2 text-uppercase desc-en",
  style: {
    "width": "9rem"
  }
};
const _hoisted_33 = {
  class: "container py-5 win-win"
};
const _hoisted_34 = {
  class: "position-relative"
};
const _hoisted_35 = {
  class: "position-absolute start-0 top-0 w-100 h-100 p-5 d-flex align-items-center justify-content-between"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_cache[6] || (_cache[6] = _createElementVNode("div", {
    class: "position-relative d-flex summary"
  }, [_createElementVNode("div", {
    class: "d-flex justify-content-end"
  }, [_createElementVNode("img", {
    src: _imports_0,
    class: "w-100",
    alt: ""
  })])], -1)), _createElementVNode("div", _hoisted_2, [_cache[1] || (_cache[1] = _createElementVNode("div", {
    class: "py-5 text-center fw-bold rowTitle"
  }, "合作伙伴", -1)), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.avatarList, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      key: index,
      class: "p-4"
    }, [_createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createElementVNode("img", {
      src: item.img,
      alt: "#"
    }, null, 8, _hoisted_8), _createElementVNode("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, _toDisplayString(item.name), 1), _createElementVNode("div", _hoisted_11, _toDisplayString(item.address), 1)])]), _createElementVNode("div", _hoisted_12, [_createElementVNode("img", {
      src: item.star,
      alt: "#"
    }, null, 8, _hoisted_13)])]), _createElementVNode("div", _hoisted_14, _toDisplayString(item.desc), 1)])]);
  }), 128))]), _createElementVNode("div", _hoisted_15, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.avatarList2, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      key: index,
      class: "p-4"
    }, [_createElementVNode("div", _hoisted_16, [_createElementVNode("div", _hoisted_17, [_createElementVNode("div", _hoisted_18, [_createElementVNode("img", {
      src: item.img,
      alt: "#"
    }, null, 8, _hoisted_19), _createElementVNode("div", _hoisted_20, [_createElementVNode("div", _hoisted_21, _toDisplayString(item.name), 1), _createElementVNode("div", _hoisted_22, _toDisplayString(item.address), 1)])]), _createElementVNode("div", _hoisted_23, [_createElementVNode("img", {
      src: item.star,
      alt: "#"
    }, null, 8, _hoisted_24)])]), _createElementVNode("div", _hoisted_25, _toDisplayString(item.desc), 1)])]);
  }), 128))])])]), _createElementVNode("div", _hoisted_26, [_cache[3] || (_cache[3] = _createElementVNode("div", {
    class: "mt-5 rowTitle"
  }, "招募对象", -1)), _createElementVNode("div", _hoisted_27, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.list, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      key: index,
      class: "col-3 d-flex flex-column justify-content-between align-items-center"
    }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item, (item2, index2) => {
      return _openBlock(), _createElementBlock("div", {
        key: index2,
        class: _normalizeClass(["position-relative", {
          'mt-3': index2 == 1
        }])
      }, [_createElementVNode("img", {
        src: item2.img,
        alt: "",
        class: "w-100"
      }, null, 8, _hoisted_28), _createElementVNode("div", _hoisted_29, [_createElementVNode("div", _hoisted_30, [_createElementVNode("div", {
        style: _normalizeStyle([{
          "width": "0.25rem",
          "height": "0.8rem"
        }, {
          backgroundColor: item2.blue ? 'white' : 'blue'
        }])
      }, null, 4), _createElementVNode("div", {
        class: "ms-2 desc-cn",
        style: _normalizeStyle({
          color: item2.blue ? 'white' : 'black'
        })
      }, _toDisplayString(item2.name), 5)]), _createElementVNode("div", _hoisted_31, [_cache[2] || (_cache[2] = _createElementVNode("div", {
        style: {
          "width": "0.25rem",
          "height": "0.8rem"
        }
      }, null, -1)), _createElementVNode("div", _hoisted_32, _toDisplayString(item2.en), 1)])])], 2);
    }), 128))]);
  }), 128))])]), _createElementVNode("div", _hoisted_33, [_createElementVNode("div", _hoisted_34, [_cache[5] || (_cache[5] = _createElementVNode("img", {
    src: _imports_1,
    alt: "",
    style: {
      "width": "100%"
    }
  }, null, -1)), _createElementVNode("div", _hoisted_35, [_cache[4] || (_cache[4] = _createElementVNode("div", null, [_createElementVNode("img", {
    src: _imports_2,
    alt: ""
  }), _createElementVNode("div", {
    class: "fs-1 fw-bold text-white"
  }, "相互拓展合作共赢")], -1)), _createElementVNode("button", {
    onClick: _cache[0] || (_cache[0] =
    //@ts-ignore
    (...args) => $options.contactUs && $options.contactUs(...args)),
    class: "px-4 btn fs-4 rounded rounded-5 bg-white",
    style: {
      "color": "#2961FE"
    }
  }, " 立即申请 ")])])])]);
}