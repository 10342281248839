import { avatarList, avatarList2 } from "@/views/service/shop/data";
export default {
  name: 'PartnerIndex',
  data() {
    return {
      list: [[{
        name: '培训机构',
        en: 'Training institution',
        img: require('@/assets/banner/st_img_pxjz.png')
      }, {
        name: '律师事务所',
        en: 'Law firm',
        img: require('@/assets/banner/st_img_lssws.png')
      }], [{
        name: '货运代理',
        en: 'Freight forwarder',
        img: require('@/assets/banner/st_img_hydl.png'),
        blue: true
      }, {
        name: '会计事务所',
        en: 'Accounting firm',
        img: require('@/assets/banner/st_img_hjssws.png')
      }], [{
        name: '本地仓储',
        en: 'Local storage',
        img: require('@/assets/banner/st_img_bdcc.png')
      }, {
        name: '知识产权机构',
        en: 'Intellectual property agency',
        img: require('@/assets/banner/st_img_zscqjg.png'),
        blue: true
      }], [{
        name: '本地货盘',
        en: 'Local pallet',
        img: require('@/assets/banner/st_img_bdhp.png')
      }, {
        name: '认证检测机构',
        en: 'Certification and testing organization',
        img: require('@/assets/banner/st_img_rzjcjg.png')
      }]],
      avatarList,
      avatarList2
    };
  },
  methods: {
    contactUs() {
      console.log('contactUs');
    }
  }
};