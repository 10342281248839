export default {
  btn: {
    contactUs: 'Contact Us',
    business: 'Business Cooperation',
    aboutUs: 'About Us',
    articleAgreement: 'Articles Of Agreement',
    login: 'Login'
  },
  homeText: {
    first: "The first in the world",
    title: "Local stores cross-border joint guaranteed trading platform",
    des: "XShopee(Tianping Platform) the world's first cross-border joint venture of local stores secured trading platform, to help the operation of safe payment, help the owner to earn income, for the global cross-border joint venture of local stores escort.",
    safeTextTitle: "Ensure the safety of operation payment",
    safeTextDes: "Ensure the security of operation return Guarantee the security of operation return guarantee the security of operation return"
  }
}