import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue";
import _imports_0 from '@/assets/banner/业务banner1920.png';
import _imports_1 from '@/assets/banner/service-footer-bg.png';
import _imports_2 from '@/assets/logo/logo.white.png';
const _hoisted_1 = {
  class: "partner-wrapper",
  style: {
    "background-color": "#f6f9fe"
  }
};
const _hoisted_2 = {
  class: "container mt-5 recruit"
};
const _hoisted_3 = {
  class: "my-5 d-flex justify-content-around"
};
const _hoisted_4 = ["onClick"];
const _hoisted_5 = {
  class: "bg-white rounded rounded-5",
  style: {
    "transition": "width 5s ease"
  }
};
const _hoisted_6 = {
  class: "position-relative"
};
const _hoisted_7 = ["src"];
const _hoisted_8 = {
  key: 0,
  class: "position-absolute start-0 top-0 w-100 h-100"
};
const _hoisted_9 = {
  class: "px-5 py-5 d-flex justify-content-between align-items-center text-white"
};
const _hoisted_10 = {
  class: "fs-4 text-uppercase"
};
const _hoisted_11 = {
  class: "fs-4 text-uppercase"
};
const _hoisted_12 = {
  key: 0,
  class: "desc"
};
const _hoisted_13 = {
  class: "container mt-5 py-5 bg-white join"
};
const _hoisted_14 = {
  class: "my-5 d-flex flex-wrap"
};
const _hoisted_15 = ["src"];
const _hoisted_16 = {
  class: "flex-grow-1"
};
const _hoisted_17 = {
  class: "num"
};
const _hoisted_18 = {
  class: "container py-5 win-win"
};
const _hoisted_19 = {
  class: "position-relative"
};
const _hoisted_20 = {
  class: "position-absolute start-0 top-0 w-100 h-100 p-5 d-flex align-items-center justify-content-between"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_cache[7] || (_cache[7] = _createElementVNode("div", {
    class: "position-relative d-flex summary"
  }, [_createElementVNode("div", {
    class: "d-flex justify-content-end"
  }, [_createElementVNode("img", {
    src: _imports_0,
    class: "w-100",
    alt: ""
  })])], -1)), _createElementVNode("div", _hoisted_2, [_cache[3] || (_cache[3] = _createElementVNode("div", {
    class: "rowTitle"
  }, "招募对象", -1)), _createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.list, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      key: index,
      onClick: $event => $data.active = index,
      class: "d-flex flex-column align-items-center"
    }, [_createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createElementVNode("img", {
      src: index == $data.active ? item.active : item.img,
      alt: ""
    }, null, 8, _hoisted_7), index == $data.active ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_10, _toDisplayString(item.en), 1), _createElementVNode("div", _hoisted_11, _toDisplayString(item.en2), 1), _cache[1] || (_cache[1] = _createElementVNode("div", {
      class: "pb-3",
      style: {
        "border-bottom": "white solid 0.25rem",
        "width": "1rem"
      }
    }, null, -1))]), _cache[2] || (_cache[2] = _createElementVNode("div", null, [_createElementVNode("div", {
      class: "text-end text-secondary",
      style: {
        "font-size": "0.8rem",
        "width": "5rem"
      }
    }, " Look Forward To Your Joining ")], -1))])])) : _createCommentVNode("", true)]), _createElementVNode("div", {
      class: "name",
      style: _normalizeStyle({
        color: index == $data.active ? '#2961FE' : 'black'
      })
    }, _toDisplayString(item.name), 5), index == $data.active ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(item.desc), 1)) : _createCommentVNode("", true)])], 8, _hoisted_4);
  }), 128))])]), _createElementVNode("div", _hoisted_13, [_cache[4] || (_cache[4] = _createElementVNode("div", {
    class: "rowTitle"
  }, "如何加入", -1)), _createElementVNode("div", _hoisted_14, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.joinList, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      key: index,
      class: "col-6 d-flex flex-column justify-content-between align-items-center"
    }, [_createElementVNode("div", {
      class: _normalizeClass(["d-flex align-items-center w-100 p-3", {
        'flex-row-reverse': index % 2 == 0
      }])
    }, [_createElementVNode("img", {
      src: item.img,
      alt: ""
    }, null, 8, _hoisted_15), _createElementVNode("div", {
      class: _normalizeClass(["h-100 d-flex align-items-center flex-grow-1 px-3 rounded rounded-3", {
        'flex-row-reverse': index % 2 == 0
      }]),
      style: {
        "background-color": "#F6F9FE"
      }
    }, [_createElementVNode("div", _hoisted_16, [_createElementVNode("div", {
      class: _normalizeClass(["w-100 name", {
        'text-end': index % 2 == 0
      }])
    }, _toDisplayString(item.name), 3), _createElementVNode("div", {
      class: _normalizeClass(["w-100 desc", {
        'text-end': index % 2 == 0
      }])
    }, _toDisplayString(item.desc), 3)]), _createElementVNode("div", _hoisted_17, _toDisplayString(item.index), 1)], 2)], 2)]);
  }), 128))])]), _createElementVNode("div", _hoisted_18, [_createElementVNode("div", _hoisted_19, [_cache[6] || (_cache[6] = _createElementVNode("img", {
    src: _imports_1,
    alt: "",
    style: {
      "width": "100%"
    }
  }, null, -1)), _createElementVNode("div", _hoisted_20, [_cache[5] || (_cache[5] = _createElementVNode("div", null, [_createElementVNode("img", {
    src: _imports_2,
    alt: ""
  }), _createElementVNode("div", {
    class: "fs-1 fw-bold text-white"
  }, "推荐我们赚取佣金")], -1)), _createElementVNode("button", {
    onClick: _cache[0] || (_cache[0] =
    //@ts-ignore
    (...args) => $options.contactUs && $options.contactUs(...args)),
    class: "px-4 btn fs-4 rounded rounded-5 bg-white",
    style: {
      "color": "#2961FE"
    }
  }, " 立即申请 ")])])])]);
}