import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue";
import _imports_0 from '@/assets/banner/service-shop-bg-2.png';
import _imports_1 from '@/assets/logo/logo.white.png';
const _hoisted_1 = {
  class: "service-shop-container"
};
const _hoisted_2 = {
  class: "page_item_3"
};
const _hoisted_3 = ["src", "alt"];
const _hoisted_4 = {
  class: "left"
};
const _hoisted_5 = ["onClick"];
const _hoisted_6 = {
  class: "title"
};
const _hoisted_7 = {
  key: 0,
  class: "des"
};
const _hoisted_8 = {
  class: "page_item_4"
};
const _hoisted_9 = {
  class: "item_right"
};
const _hoisted_10 = ["src", "alt"];
const _hoisted_11 = {
  class: "page_item_5"
};
const _hoisted_12 = {
  class: "avatar-list"
};
const _hoisted_13 = {
  class: "avatar-item-top-left"
};
const _hoisted_14 = ["src"];
const _hoisted_15 = {
  class: "name-info"
};
const _hoisted_16 = {
  class: "name"
};
const _hoisted_17 = {
  class: "address"
};
const _hoisted_18 = {
  class: "avatar-item-top-right"
};
const _hoisted_19 = ["src"];
const _hoisted_20 = {
  class: "desc"
};
const _hoisted_21 = {
  class: "avatar-list"
};
const _hoisted_22 = {
  class: "avatar-item-top-left"
};
const _hoisted_23 = ["src"];
const _hoisted_24 = {
  class: "name-info"
};
const _hoisted_25 = {
  class: "name"
};
const _hoisted_26 = {
  class: "address"
};
const _hoisted_27 = {
  class: "avatar-item-top-right"
};
const _hoisted_28 = ["src"];
const _hoisted_29 = {
  class: "desc"
};
import { infoList, payList, avatarList, avatarList2 } from './data';
import initHook from './composables/initHook';
import OperationHook from '@/views/home/composables/operationHook';
const __default__ = {
  name: 'ServiceShop'
};
export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {
    const {
      activeIndex
    } = initHook();
    const {
      clickInfoHandle
    } = OperationHook({
      activeIndex
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_cache[3] || (_cache[3] = _createElementVNode("div", {
        class: "header"
      }, [_createElementVNode("img", {
        src: _imports_0,
        alt: ""
      })], -1)), _createElementVNode("div", _hoisted_2, [_cache[0] || (_cache[0] = _createElementVNode("header", null, " 让店主收益可持续 ", -1)), _createElementVNode("section", null, [_createElementVNode("div", {
        class: "right",
        style: _normalizeStyle({
          backgroundImage: `url(${_unref(infoList)[_unref(activeIndex)].mainBg})`
        })
      }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(infoList)[_unref(activeIndex)].imgList, (item, index) => {
        return _openBlock(), _createElementBlock("img", {
          class: _normalizeClass(item.class),
          key: index,
          src: item.src,
          alt: item.alt
        }, null, 10, _hoisted_3);
      }), 128))], 4), _createElementVNode("ul", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(infoList), (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          key: index,
          class: _normalizeClass(_unref(activeIndex) === index ? 'active' : ''),
          onClick: $event => _unref(clickInfoHandle)(index)
        }, [_createElementVNode("span", _hoisted_6, _toDisplayString(item.title), 1), _unref(activeIndex) === index ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(item.des), 1)) : _createCommentVNode("", true)], 10, _hoisted_5);
      }), 128))])])]), _createElementVNode("div", _hoisted_8, [_cache[1] || (_cache[1] = _createElementVNode("div", {
        class: "item_left"
      }, [_createElementVNode("p", {
        class: "title"
      }, "合作支付机构"), _createElementVNode("span", {
        class: "des"
      }, " XShopee与多家支付机构合作， 致力于为您打造更为便捷的支付体验。 ")], -1)), _createElementVNode("div", _hoisted_9, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(payList), item => {
        return _openBlock(), _createElementBlock("li", {
          key: item.id
        }, [_createElementVNode("img", {
          src: item.img,
          alt: item.id
        }, null, 8, _hoisted_10)]);
      }), 128))])])]), _createElementVNode("div", _hoisted_11, [_cache[2] || (_cache[2] = _createElementVNode("div", {
        class: "header"
      }, "他/她们都在用XShopee", -1)), _createElementVNode("div", _hoisted_12, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(avatarList), (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          key: index,
          class: "avatar-item"
        }, [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_13, [_createElementVNode("img", {
          src: item.img,
          alt: "#"
        }, null, 8, _hoisted_14), _createElementVNode("div", _hoisted_15, [_createElementVNode("div", _hoisted_16, _toDisplayString(item.name), 1), _createElementVNode("div", _hoisted_17, _toDisplayString(item.address), 1)])]), _createElementVNode("div", _hoisted_18, [_createElementVNode("img", {
          src: item.star,
          alt: "#"
        }, null, 8, _hoisted_19)])]), _createElementVNode("div", _hoisted_20, _toDisplayString(item.desc), 1)]);
      }), 128))]), _createElementVNode("div", _hoisted_21, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(avatarList2), (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          key: index,
          class: "avatar-item"
        }, [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_22, [_createElementVNode("img", {
          src: item.img,
          alt: "#"
        }, null, 8, _hoisted_23), _createElementVNode("div", _hoisted_24, [_createElementVNode("div", _hoisted_25, _toDisplayString(item.name), 1), _createElementVNode("div", _hoisted_26, _toDisplayString(item.address), 1)])]), _createElementVNode("div", _hoisted_27, [_createElementVNode("img", {
          src: item.star,
          alt: "#"
        }, null, 8, _hoisted_28)])]), _createElementVNode("div", _hoisted_29, _toDisplayString(item.desc), 1)]);
      }), 128))])]), _cache[4] || (_cache[4] = _createStaticVNode("<div class=\"footer\" data-v-893cc03e><div class=\"main-content\" data-v-893cc03e><div class=\"left\" data-v-893cc03e><div data-v-893cc03e><img src=\"" + _imports_1 + "\" alt=\"\" data-v-893cc03e></div><div data-v-893cc03e>让店主收益可持续</div></div><div class=\"right\" data-v-893cc03e><span data-v-893cc03e>联系我们</span></div></div></div>", 1))]);
    };
  }
});