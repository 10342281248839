import IconSecure from '@/assets/icon/icon_secure.png'
import IconEfficient from "@/assets/icon/icon_efficient.png"
import IconTransparent from "@/assets/icon/icon_transparent.png"
import IconCompliance from "@/assets/icon/icon_compliance.png"
import { PayItem, SignItem } from '@/views/home/type'
export const signList: Array<SignItem> = [
    {
      title: "安全",
      des: "每笔订单店主必须预存保证金在平台",
      img: IconSecure,
      id: Symbol('安全')
    },
    {
      title: "高效",
      des: "订单完成拨款，系统T+0自动结算到平台钱包",
      img: IconEfficient,
      id: Symbol('高效')
    },
    {
      title: "透明",
      des: "没有中间商赚差价，直通店主透明合作",
      img: IconTransparent,
      id: Symbol('透明')
    },
    {
      title: "合规",
      des: "所有资金通过银行、三方支付机构监管",
      img: IconCompliance,
      id: Symbol('合规')
    }
  ]
  export const payList: Array<PayItem> = [
    {
      id: 'pay1',
      img: require("@/assets/payIcon/1.png")
    },
    {
      id: 'pay2',
      img: require("@/assets/payIcon/2.png")
    },
    {
      id: 'pay3',
      img: require("@/assets/payIcon/3.png")
    },
    {
      id: 'pay4',
      img: require("@/assets/payIcon/4.png")
    },
    {
      id: 'pay5',
      img: require("@/assets/payIcon/5.png")
    },
    {
      id: 'pay6',
      img: require("@/assets/payIcon/6.png")
    }
  ]

  export const avatarList = [
    {
        company: '广州环球易购网',
        name: '王总监',
        img: require("@/assets/banner/avatar-oper-1.png"),
        title: '平台更有说服力！',
        desc: '我通过熟人介绍直接跟当地店主合作的，但每次店主回信息慢了，我都担心害怕，比起相信店主个人的道德水平，我更相信平台的规则。',
    },
    {
        company: '台北丝路云商集',
        name: '刘主管',
        img: require("@/assets/banner/avatar-oper-2.png"),
        title: `平台安全有保障！`,
        desc: '相信天平平台更安全，我被店主“跑路”130万台币，刚开始合作的很好，后来突然不回信息，公司差点倒闭。',
    },
    {
        company: '深圳昭化寺时代',
        name: '张经理',
        img: require("@/assets/banner/avatar-oper-3.png"),
        title: `安全性和高效性震惊了我！`,
        desc: '我被广州的服务商跑了30万台币，服务商一直推诿，说他也被店主跑路，一直不给我解决，天平平台解决了我的后顾之忧。',
    },
    {
        company: '广州beach生鲜',
        name: 'Jerry经理',
        img: require("@/assets/banner/avatar-oper-4.png"),
        title: '回款结算高效率！',
        desc: '天平平台结算很快，以前服务商到账一周之后才结算，天平平台到账自动结算，很有效率。',
    },
    {
        company: '东莞喜华食品',
        name: '唐经理',
        img: require("@/assets/banner/avatar-oper-5.png"),
        title: '平台值得推荐！',
        desc: '这个平台很不错，现在跨境电商行业利润越来越低，本金安全比赚钱更重要。',
    },
    {
        company: '广州星悦海淘铺',
        name: '王经理',
        img: require("@/assets/banner/avatar-oper-6.png"),
        title: '账户资金很安全！',
        desc: '因为服务商非法换汇，导致我的账户被冻结，并被“请”去喝茶，想想都怕。天平平台金流通过正规的三方支付机构进来，再也不用担心收到不安全的钱了。',
    },
]