import { createI18n } from 'vue-i18n';
import en from "./en"
import zh from "./zh-cn"


const i18n = createI18n({
  locale: 'zh-cn', 
  fallbackLocale: 'en', 
  messages: {
    en,
    'zh-cn': zh,
  },
});

export default i18n;