import { RouteRecordRaw } from 'vue-router';

const moduleRouteFile = require.context('@/views', true, /\.vue$/);
export const routeEngineering = (routerList: Array<RouteRecordRaw>): Array<RouteRecordRaw> => {
    const moduleFileKeys = moduleRouteFile.keys();
    for (const routeItem of routerList) {
        if (!routeItem.redirect) {
            const comIndex = moduleFileKeys.findIndex((key: string) => {
                if (key.includes('index.vue')) {
                    const targetPath = formatFilePathRoutePath(key);
                    return targetPath === routeItem.path;
                }
                return false;
            });
            let component = null;
            if(comIndex !== -1) {
                component = moduleRouteFile(moduleFileKeys[comIndex]).default;
            }else {
                component = () => import('@/views/home/index.vue');
            }
            routeItem.component = component;
        }
        if(routeItem.children && routeItem.children.length) {
            return routeEngineering(routeItem.children);
        }
    }
    return routerList;
}
export const formatFilePathRoutePath = (path: string) => {
    if (typeof path !== "string") {
        return "";
    }
    const reg = /^(.){1}|(\/index.vue){1}$/g;
    return path.replace(reg, "");
}
