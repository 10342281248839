import { Ref } from "vue";

export default function OperationHook  (state: {activeIndex: Ref<number>}) {
    const { activeIndex } = state
    const clickInfoHandle = (index: number) => {
        console.log('clickContactUs');
        activeIndex.value = index
    }
    return {
        clickInfoHandle
    }
}

