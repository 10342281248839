import { createApp } from 'vue'
import App from './App.vue'
import router from "./router/index"
import i18n from './locales/i18n'
import InitElementIcons from './plugins/element'
import "./styles/global.css"
import 'element-plus/dist/index.css'

const app = createApp(App);
app.use(router)
app.use(i18n)
InitElementIcons(app)
app.mount('#app')
