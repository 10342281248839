import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/banner/price-comparison.png';
const _hoisted_1 = {
  class: "about-wrapper"
};
const _hoisted_2 = {
  class: "promotion-container"
};
const _hoisted_3 = {
  class: "promotion-container1"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_cache[2] || (_cache[2] = _createStaticVNode("<div class=\"banner-container\" data-v-4bbc407e><img src=\"" + _imports_0 + "\" alt=\"价格对比\" class=\"banner-img\" data-v-4bbc407e><div class=\"left-label\" data-v-4bbc407e><div class=\"price\" data-v-4bbc407e>免费</div><div class=\"type\" data-v-4bbc407e>- 店主端 -</div></div><div class=\"center-label\" data-v-4bbc407e>收费标准</div><div class=\"right-label\" data-v-4bbc407e><div class=\"price\" data-v-4bbc407e>3%</div><div class=\"type\" data-v-4bbc407e>- 运营端 -</div></div></div>", 1)), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_cache[1] || (_cache[1] = _createElementVNode("div", {
    class: "promotion-text"
  }, [_createTextVNode(" 运营端新用户加入"), _createElementVNode("span", {
    class: "highlight"
  }, "免3个月"), _createTextVNode("服务费 ")], -1)), _createElementVNode("button", {
    class: "contact-btn",
    onClick: _cache[0] || (_cache[0] =
    //@ts-ignore
    (...args) => $options.handleContact && $options.handleContact(...args))
  }, "联系我们")])])]);
}