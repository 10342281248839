export default {
  btn: {
    contactUs: '联系我们',
    business: '商务合作',
    aboutUs: '关于我们',
    articleAgreement: "协议条款",
    login: '登录'
  },
  homeText: {
    first: "全球首家",
    title: "本土店跨境联营担保交易平台",
    des: "XShopee(天平平台)全球首家本土店跨境联营担保交易平台，帮运营安全回款，帮店主赚取收益，为全球本土店跨境联营保驾护航。",
    safeTextTitle: "保障运营回款安全",
    safeTextDes: "保障运营回款安全保障运营回款安全保障运营回款安全保障运营回款安全"
  },
  nav: {
    pricing: "关于收费标准",
    aboutXshopee: "了解xshopee"
  }
}